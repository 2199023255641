
#id659ed866b0184704f908792d {
  #iinz {
padding : 10px; 
display : block; 
} 
#ito6 {
padding : 10px; 
min-height : 256px; 
} 
#icaf {
padding : 10px; 
} 
#iqqg {
padding : 5px; 
margin-top : 5px; 
margin-bottom : 5px; 
} 
#in8o {
padding : 5px; 
margin-top : 5px; 
margin-bottom : 5px; 
} 

  
  
}
  